import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { IndexedDBService } from "core/services";
import { setFormAvailability } from "core/utilities";
import { untilDestroyed } from "ngx-take-until-destroy";
import { debounceTime, first, pairwise, startWith } from "rxjs/operators";
import { rootSelectors } from 'core/root-store';
import { AuthenticatedUser } from "core/models";
import { Actions, ofType } from "@ngrx/effects";
import { INameReference } from "core/config";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "core-edit-object",
  template: "",
})
export class EditObjectComponent<T> implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public updateAction: any;
  public debounceTime: number;

  public dispatchUpdateAction = this.dispatchUpdateMinimalObjectAction;

  public _editObject: any;

  @Input() set editObject(value: T) {
    this._editObject = value;
    this.setValueFormGroup();
  }
  get editObject(): T {
    return this._editObject;
  }
  @Input() set userType(value: string) {
    this._userType = value;
    this.setUserPermission();
  };
  get userType(): string {
    return this._userType;
  }
  _userType: string;
  @Input() compareScreenData: { isSource: false, isCompare: false, mergeConflictSolutions: {}, isReview: false } = { isSource: false, isCompare: false, mergeConflictSolutions: {}, isReview: false };
  private _isReadOnly: boolean = false;
  @Input() set isReadOnly(value: boolean) {
    this._isReadOnly = value;
    this.setUserPermission()
  }
  get isReadOnly(): boolean {
    return this._isReadOnly;
  }
  authenticatedUser: AuthenticatedUser;
  @Input() metaProps: { projectId: string, isMainBranch: boolean };

  constructor(private store: Store<unknown>,
    private indexedDBService?: IndexedDBService,
    private actionsSubject$?: Actions) {
    this.store
      .pipe(select(rootSelectors.getAuthenticatedUser)).
      pipe(untilDestroyed(this)).subscribe(user => this.authenticatedUser = user);
  }

  ngOnInit() {
    this.subscribeFormGroupValueChanges();
    this.setUserPermission();
  }

  setUserPermission() {
    if ((this.userType === "ADMIN" || this.userType === "TRIAL") && !this.compareScreenData.isCompare) {
      setFormAvailability(this.formGroup, false)
    } else if (this.isReadOnly === true || this.isReadOnly === false) {
      setFormAvailability(this.formGroup, this.isReadOnly);
    }
  }

  setValueFormGroup(): void {
    if (this.editObject && this.formGroup) {
      Object.keys(this.formGroup.controls).forEach((controlName: string) => {
        if (this.formGroup.controls[controlName].pristine) {
          this.formGroup.controls[controlName].setValue(this.editObject[controlName], { emitEvent: false });
        }
        if (controlName === 'enableBranching' && this.formGroup.controls[controlName].value) {
          this.formGroup.controls['enableBranching'].setValue('enableBranching', { emitEvent: true });
        } else if (controlName === 'enableSimpleMerge' && this.formGroup.controls[controlName].value) {
          this.formGroup.controls['enableBranching'].setValue('enableSimpleBranching', { emitEvent: true });
        } else if (controlName === 'enableBranching' && !this.formGroup.controls[controlName].value) {
          this.formGroup.controls['enableBranching'].setValue('disableBranching', { emitEvent: true });
        }
      });
    }
  }

  /* 'stratWith(null)' as 'pairwise()' emits on the second and subsequent emissions from the source Observable, but not on the first emission, because there is no previous value in that case.
  To handle first emission to send only changes data for, using 'editObject' for comparing data and from second emission using 'prev' and 'next' values for comparision */

  subscribeFormGroupValueChanges(): void {
    this.formGroup.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.debounceTime), startWith(null), pairwise())
      .subscribe(([prev, next]: [Partial<T>, Partial<T>]) => {
        let changedValues: Partial<T> = {};
        if (prev === null) {

          for (let key in next) {
            if (next[key] !== undefined && this._editObject[key] !== next[key]) {
              if (!(key === 'name' && !next[key])) {
                changedValues[key] = next[key];
              }
            }
          }
        } else {
          for (let key in prev) {
            if (next[key] !== undefined && prev[key] !== next[key]) {
              if (!(key === 'name' && !next[key])) {
                changedValues[key] = next[key];
              }
            }
          }
        }
        if (this.formGroup.valid && JSON.stringify(changedValues) !== '{}') {
          this.dispatchUpdateAction(changedValues);
        } else if (!this.formGroup.pristine) {
          for (let key in next) {
            if (this.formGroup.controls[key].pristine) {
              if (!(key === 'name' && !next[key])) {
                changedValues[key] = next[key]
              }
            }
          }
          this.dispatchUpdateAction(changedValues);
        }
      });
  }

  dispatchUpdateMinimalObjectAction(value: Partial<T>): void {
    this.store.dispatch(
      new this.updateAction({
        ...value,
        _links: this.editObject["_links"],
        id: this.editObject["id"],
      })
    );
  }

  ngOnDestroy() { }

  async handleEditObjectNaming(name: string, type: string, nameRefReferenceId: string, objectTypeResourceName: string) {
    return this.indexedDBService.checkForNameReferenceUpdateByProjectId(name, type, nameRefReferenceId, objectTypeResourceName, this.authenticatedUser.accountId, this.metaProps.projectId);
  }

  listenForFailureResponse(failureActionType: string, objectId: string, nameReference: INameReference) {
    if (!nameReference?.id || !failureActionType) return;
    this.actionsSubject$?.pipe(ofType(failureActionType), first(), untilDestroyed(this))
      .subscribe(async ({ payload }: any) => {
        if (payload.id == objectId) await this.indexedDBService.removeNameReferenceByIds([nameReference.id]);
      });
  }
}
